/* sign in FORM */
.form-login-container {
    border: lightgray 1px solid;
    border-radius: 10px;
    width: 400px;
    margin: 10vh auto;
}

.form-login-container form {
    width: 100%;
    max-width: 410px;
    padding: 15px;
    margin: auto;
}

.form-login .header {
    text-align: center;
}

.form-login .header > img {
    max-height: 100px;
}

.form-login button>span {
    margin-left: 5px;
}

.form-login>input {
    border-radius: 0;
    margin-top: -1px;
}

.form-login>input:focus {
    z-index: 2;
}

.form-login>input:first-of-type {
    border-radius: .25rem .25rem 0 0;
    margin-top: 0;
}

.form-login>input:last-of-type {
    border-radius: 0 0 .25rem .25rem;
}

.form-login div>a {
    font-size: small;
}

.form-login .form-check {
    margin: .5rem 0;
}

.form-login-container input.form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}

/* Mobile */

@media screen and (max-width: 500px) {
    .form-login-container {
        width: 300px;
    }
}
