:root {
    --main-color: #f0a504;
}

.navbar-brand-logo {
    height: 1.5em;
    margin: auto 10px;
}

.brand {
    display: flex;
    transition-duration: 0.5s;
    opacity: 1;
    cursor: pointer;
}

.brand:hover {
    transition-duration: 0.5s;
    opacity: 0.8;
}

.brand > img {
    height: 25px;
    margin: auto 10px;
}

.infinitools.btn-outline-primary {
    color: var(--main-color);
    border-color: var(--main-color);
}

.btn-outline-primary.disabled {
    color: #505050;
    border-color: #909090;
}

.infinitools.btn-outline-primary:hover {
    background-color: var(--main-color);
    color: #ffffff;
}

.pointer-default {
    cursor: default;
}
